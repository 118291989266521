$roboto-font-path: "../fonts";
@import "roboto-fontface/css/roboto/sass/roboto-fontface.scss";
$font-family-sans-serif: "Roboto";

@import "@mdi/font/scss/materialdesignicons.scss";
@import "styles/fonts/tim/style.scss";
@import "bootstrap/scss/functions";

//Variables override
@import "styles/core/utils/color-generator";
@import "styles/core/utils/material.colors";
@import "styles/core/utils/functions";

$primary-palette: makeColorPalette(#03787c);
$secondary-palette: makeColorPalette(#3c5067);

@import "styles/core/utils/custom.bootstrap.variables";

//Bootstrap import
@import "bootstrap/scss/variables";
@import "styles/core/libs/bootstrap";

//Custom variable for app
@import "styles/core/utils/variables";

//Bootstrap custom

//Default component variables
//Theme component variables
@import "styles/core/component/forms";
@import "styles/core/component/alert";
@import "styles/core/component/buttons";
@import "styles/core/component/tables";
@import "styles/core/component/breadcrumb";
@import "styles/core/component/search-tag";
@import "styles/core/component/cards";
@import "styles/core/component/dropdown";
@import "styles/core/component/tabs";
@import "styles/core/component/smart-actions";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

@import "src/styles/core/component/_ng-select.scss";

//Default global styles
html,
body,
app-root {
  height: 100%;
  margin: 0;
}

//Customs theme style
:root {
  @include root-variables;
}

@each $size, $value in $font-sizes {
  .fs-#{$size} {
    font-size: $value !important;
  }
}

body {
  font-size: var(--global-font-size);
}

a {
  text-decoration: none;
}

label {
  font-weight: var(--font-weight-bold);
  color: var(--label-color);
  margin-bottom: 0;

  &.empty {
    color: var(--label-empty-color);
  }
}

main {
  overflow: auto;
}
.module-home {
  @extend .mdi, .mdi-home;
}
.module-leads {
  @extend .mdi, .mdi-finance;
}
.module-content {
  @extend .mdi, .mdi-view-grid-outline;
}
.module-configuration {
  @extend .mdi, .mdi-cog;
}

.pt-2,
.sub-header-row > .row:not(:first-child),
.tab-pane,
.py-2,
.page-container {
  padding-top: 0.5rem !important;
}
.mr-2,
.dropdown-custom .tt-header,
#navbar-tools .dropdown-toggle i,
.mx-2,
.input-daterange .input-group-addon {
  margin-right: 0.5rem !important;
}
.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > button[id^="GridDelete_"],
.input-edit .input-edit-btn .btn-group-sm > input + label,
.btn-group-sm > .notification-link,
.tag-picker.btn-group-sm > button,
.tag-picker-light.btn-group-sm > button,
.btn-group-sm > .no-result-btn,
.avatar-upload .avatar-edit .btn-group-sm > input + label,
#Grid_columnchooser,
button[id^="GridDelete_"],
.input-edit .input-edit-btn input + label,
.no-result-btn,
.avatar-upload .avatar-edit input + label,
.avatar-upload .avatar-delete {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

body {
  font-size: var(--global-font-size);

  @each $color, $palette in $theme-colors-palette {
    --bs-#{$color}-light-text: #{map-get($palette, 500)};

    @if ($color == "dark") {
      --bs-#{$color}-light-rgb: #{to-rgb(map-get($palette, 100))};
    } @else if ($color == "light") {
      --bs-#{$color}-light-rgb: #{to-rgb(map-get($palette, 400))};
    } @else {
      --bs-#{$color}-light-rgb: #{to-rgb(map-get($palette, 50))};
    }
  }
}

.NgxEditor__Content {
  min-height: 350px;
  overflow: auto;
}
