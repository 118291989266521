/// <reference path="../intellisense.scss" />
.card-light {
  .card-header {
    background: none;
  }

  .card-footer {
    background: none;
    border-color: transparent;
  }
}

@include media-breakpoint-up(sm) {
  .page-container > .card {
    margin: 0 auto;
    width: var(--card-max-width);
    max-width: calc(100vw - var(--sidebar-width) - (var(--padding-md) * 2));
  }
}

.card {
  --bs-card-bg: var(--card-bg);
  display: flex;
  position: relative;
  min-width: 400px;
}

.cards-grid-container {
  margin: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;
  justify-content: center;
  grid-auto-rows: minmax(90px, max-content);
}

.data-card {
  border: none;
  box-shadow: var(--box-shadow-sm);
  &:hover {
    box-shadow: var(--box-shadow);
  }

  //card-state (la barre sur le coté gauche de chaque carte qui indique l'état)
  .card-state {
    width: 5px;
    height: 100%;
    left: 0;
    position: absolute;
  }
  //card structure
  .card-body {
    display: flex;
    overflow: hidden;
    position: relative;
    padding: var(--padding-md);
  }
  .card-chart-body {
    //display: flex;
    overflow: hidden;
    position: relative;
    padding: var(--padding-md);
  }

  .card-right {
    padding-left: var(--padding-sm);
    overflow: hidden;
    min-width: var(--card-right-min-width);

    i {
      font-size: 50px;
    }
  }

  .card-chart {
    //width: 760px !important;
    //height: 250px !important;
  }

  .card-chart-content {
    display: flex;
    flex-direction: column;
    padding-right: var(--padding-md);
    //border-right: 1px var(--border-color) solid;

    & > div {
      margin-bottom: var(--margin-sm);
    }

    & > div:last-child {
      margin-top: auto;
      margin-bottom: 0px;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    padding-left: var(--padding-md) !important;

    & > div {
      margin-bottom: var(--margin-sm);
    }

    & > div:last-child {
      margin-top: auto;
      margin-bottom: 0px;
    }
  }
  //card titles
  .card-title {
    font-size: var(--font-size-md);
    margin-bottom: var(--margin-sm);
  }

  .card-subtitle {
    font-size: var(--font-size-sm);
    color: rgba(0, 0, 0, 0.5) !important;
  }
  //tags
  .badge {
    font-size: var(--font-size-xxs);
    margin-bottom: var(--margin-xs);
    transition: all ease 100ms;
  }
  //card up right corner
  .card-ur-corner-wrapper {
    //display: flex;
    position: absolute;
    right: 0;
    top: 0;
    //z-index: 2;
  }
  //bottom right
  .card-ur-corner-wrapper-bottom-right {
    //display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    //z-index: 2;
  }

  .card-band {
    align-self: start;
    display: inline-block;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: var(--font-size-xxs);
    padding: var(--padding-xs) / 2 var(--padding-sm);
  }
  //card dropdown
  .card-menu {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }

  .stretched-link::after {
    z-index: auto;
  }
}

.card-left {
  overflow: hidden;
  border-right: 1px var(--border-color) solid;
  min-width: calc(var(--card-img-size) + var(--padding-sm));

  i {
    font-size: 50px;
  }
}

.card-img {
  width: var(--card-img-size);
  height: var(--card-img-size);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.card-header {
  padding: 0;
}

//Si la card est active ou non
.data-card.card-active {
  border-left-color: green;
  border-left-width: medium;
  border-left-style: solid;
}
.data-card.card-non-active {
  border-left-color: red;
  border-left-width: medium ;
  border-left-style: solid;
}


// .card-2x2 {
//   grid-column: span 2;
//   grid-row: span 2;
// }

// .card-2x1 {
//   grid-column: span 2;
//   grid-row: span 1;
// }

// .card-1x2 {
//   grid-column: span 1;
//   grid-row: span 2;
// }

// .data-card {
//   border: none;
//   box-shadow: $box-shadow-sm;

//   &:hover {
//     box-shadow: $box-shadow;
//   }
//   //card-state (la barre sur le coté gauche de chaque carte qui indique l'état)
//   .card-state {
//     width: 5px;
//     height: 100%;
//     left: 0;
//     position: absolute;
//   }
//   //card structure
//   .card-body {
//     display: flex;
//     overflow: hidden;
//     position: relative;
//     @extend .p-3;
//   }

//   .card-left {
//     @extend .pr-2;
//     overflow: hidden;
//     min-width: calc(#{$card-img-size} + #{$padding-sm});

//     i {
//       font-size: 50px;
//     }
//   }

//   .card-content {
//     display: flex;
//     flex-direction: column;
//     @extend .pl-3;
//     border-left: 1px $border-color solid;

//     & > div {
//       @extend .mb-2;
//     }

//     & > div:last-child {
//       margin-top: auto;
//       @extend .mb-0;
//     }
//   }
//   //card titles
//   .card-title {
//     font-size: $font-size-sm;
//     @extend .mb-2;
//   }

//   .card-subtitle {
//     font-size: $font-size-sm;
//     @extend .text-black-50;
//   }
//   //tags
//   .badge {
//     font-size: $font-size-xxs;
//     @extend .mb-1;
//     transition: all ease 100ms;
//   }
//   //card up right corner
//   .card-ur-corner-wrapper {
//     @extend .d-flex;
//     position: absolute;
//     right: 0;
//     top: 0;
//     //z-index: 2;
//   }

//   .card-band {
//     @extend .align-self-start;
//     display: inline-block;
//     color: white;
//     text-align: center;
//     text-transform: uppercase;
//     font-size: $font-size-xxs;
//     padding: $padding-xs/2 $padding-sm;
//   }
//   //card dropdown
//   .card-menu {
//     .dropdown-toggle {
//       &:after {
//         display: none;
//       }
//     }
//   }

//   .stretched-link::after {
//     z-index: auto;
//   }
// }
// //ce code est fait pour les cards sans image, il enleve la bordure et le padding présent au milieu
// .data-card-no-img > .card-body > .card-content {
//   border-left: none;
//   padding-left: 0 !important;
// }

// .card-header {
//   @extend .p-0;
// }
