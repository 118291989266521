@mixin alert-theme($alert-color, $alert-bg, $alert-border-color) {
  --bs-alert-color: #{$alert-color};
  --bs-alert-bg: #{$alert-bg};
  --bs-alert-border-color: #{$alert-border-color};
}

.alert-primary {
  @include alert-theme(
    map-get($primary-palette, 800),
    map-get($primary-palette, 50),
    map-get($primary-palette, 100)
  );
}

.alert-secondary {
  @include alert-theme(
    map-get($secondary-palette, 800),
    map-get($secondary-palette, 50),
    map-get($secondary-palette, 100)
  );
}

.alert-success {
  @include alert-theme(
    map-get($success-palette, 800),
    map-get($success-palette, 50),
    map-get($success-palette, 100)
  );
}

.alert-info {
  @include alert-theme(
    map-get($info-palette, 800),
    map-get($info-palette, 50),
    map-get($info-palette, 100)
  );
}

.alert-warning {
  @include alert-theme(
    map-get($info-palette, 800),
    map-get($info-palette, 50),
    map-get($info-palette, 100)
  );
}

.alert-danger {
  @include alert-theme(
    map-get($danger-palette, 800),
    map-get($danger-palette, 50),
    map-get($danger-palette, 100)
  );
}
