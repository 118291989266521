.search-tag {
  @extend .d-inline-flex;
  border: 1px solid var(--search-tag-border);
  margin-right: var(--padding-sm);
  font-size: var(--font-size-xs);
}

.search-tag-prepend {
  background: var(--search-tag-prepend);
  padding: var(--search-tag-padding);
  color: #ffffff;
}
.search-tag-append {
  cursor: pointer;
  padding: var(--search-tag-padding);

  &:hover {
    background: var(--search-tag-close-hover);
  }
}
.search-tag-value {
  white-space: nowrap;
  padding: var(--search-tag-padding);
}
