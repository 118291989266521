/// <reference path="../intellisense.scss" />

//buttons
.btn-rounded {
  border-radius: 50%;
  position: relative;

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, 0);
  }
}

%common-btn-outline {
  border-color: transparent !important;
}

@each $color, $value in $theme-colors-palette {
  @if $color == "dark" {
    .btn-outline-#{$color} {
      --bs-btn-border-color: transparent;
      --bs-btn-hover-color: #{map-get($value, 500)};
      --bs-btn-hover-bg: #{map-get($value, 50)};
      --bs-btn-hover-border-color: var(--bs-btn-border-color);
      --bs-btn-active-color: #{color-contrast(map-get($value, 500))};
      --bs-btn-active-bg: #{map-get($value, 500)};
      --bs-btn-active-border-color: var(--bs-btn-border-color);
      --bs-btn-disabled-color: #{map-get($value, 200)};
      --bs-btn-disabled-bg: var(--bs-btn-border-color);
      --bs-btn-disabled-border-color: var(--bs-btn-border-color);
    }
  }
  @if $color == "light" {
    .btn-outline-#{$color} {
      --bs-btn-color: #{map-get($value, 700)};
      --bs-btn-border-color: transparent;
      --bs-btn-hover-color: #{map-get($value, 900)};
      --bs-btn-hover-bg: #{map-get($value, 100)};
      --bs-btn-hover-border-color: var(--bs-btn-border-color);
      --bs-btn-active-color: #{color-contrast(map-get($value, 400))};
      --bs-btn-active-bg: #{map-get($value, 300)};
      --bs-btn-active-border-color: var(--bs-btn-border-color);
      --bs-btn-disabled-color: #{map-get($value, 200)};
      --bs-btn-disabled-bg: var(--bs-btn-border-color);
      --bs-btn-disabled-border-color: var(--bs-btn-border-color);
    }
  } @else {
    .btn-outline-#{$color} {
      --bs-btn-color: #{map-get($value, 500)};
      --bs-btn-border-color: transparent;
      --bs-btn-hover-color: var(--bs-btn-color);
      --bs-btn-hover-bg: #{map-get($value, 50)};
      --bs-btn-hover-border-color: var(--bs-btn-border-color);
      --bs-btn-active-color: #{color-contrast(map-get($value, 500))};
      --bs-btn-active-bg: var(--bs-btn-color);
      --bs-btn-active-border-color: var(--bs-btn-border-color);
      --bs-btn-disabled-color: #{map-get($value, 200)};
      --bs-btn-disabled-bg: var(--bs-btn-border-color);
      --bs-btn-disabled-border-color: var(--bs-btn-border-color);
    }
  }
}

//New button sizes
.btn-xs {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-xs,
    $btn-border-radius-sm
  );
}
