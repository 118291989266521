.smart-actions {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-auto-rows: 60px;
  grid-gap: 1px;
  direction: rtl;
  overflow: hidden;
}
.smart-link {
  display: flex;
  align-items: center;
  padding-left: var(--padding-sm);
  padding-right: var(--padding-sm);
  padding-top: var(--padding-xs);
  padding-bottom: var(--padding-xs);

  width: var(--smart-link-width);
  //   color: $text-muted;
  direction: ltr;

  i {
    font-size: var(--h2-font-size);
  }

  &:hover {
    color: currentColor;
    background: var(--smart-link-hover-bg);
  }
  .col {
    padding-right: 0;
  }
}

a.smart-link {
  text-decoration: none !important;
  width: 100%;
  outline: 1px solid var(--border-color);
  cursor: pointer;
  color: var(--grey-600);
}

.smart-value {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  line-height: var(--font-size-lg);
}
