$icomoon-font-family: "Tim" !default;
$icomoon-font-path: "fonts" !default;

$icon-file-doc: unquote('"\\e901"');
$icon-file-jpeg: unquote('"\\e902"');
$icon-file-jpg: unquote('"\\e902"');
$icon-file-pdf: unquote('"\\e903"');
$icon-file-png: unquote('"\\e904"');
$icon-file-xls: unquote('"\\e905"');
$icon-file-zip: unquote('"\\e906"');
$icon-stock: unquote('"\\e900"');

