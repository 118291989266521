#breadcrumb {
    display: inline-flex;
    margin-top: var(--bs-spacer-2);
    margin-bottom: var(--bs-spacer-2);
}

.breadcrumb {
	margin: 0;
	padding: 0;
	background: none;

	.breadcrumb-item.active {
		font-weight: bold;
	}

	a {
		color: var(--breadcrumb-color);
	}
}