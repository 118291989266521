.table {
  --bs-table-striped-bg: var(--table-striped-order-color);
  --bs-table-active-bg: var(--table-row-active-bg);
  --bs-table-hover-bg: var(--table-hover-bg);

  thead {
    th {
      background: var(--table-head-bg);
      font-size: var(--table-head-font-size);
    }
  }

  .row-action {
    cursor: pointer;
  }

  .min-col {
    width: 1px;
  }

  td {
    font-size: var(--table-row-font-size);

    &.money {
      text-align: right;
    }

    &.total {
      font-weight: var(--bs-font-weight-semibold);
    }
  }
}
