.nav-tabs {
    margin-top: var(--margin-sm);

	.nav-link {
		color: var(--nav-tabs-color);
		text-transform: uppercase;
		font-weight: var(--font-weight-bold);

        padding-left: var(--padding-md);
        padding-right: var(--padding-md);

		&:hover {
		color: var(--nav-tabs-hover-color);
		}

		&:hover, &:active, &.active {
			border-bottom-width: 2px;
            background-color: transparent;
            border-color: transparent transparent var(--primary) transparent;
		}
	}
}